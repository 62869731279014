<template>
  <SearchLayout>
    <LoadingComponent :loading.sync="isLoading" />
    <Headings
      class="d-inline-block"
      :title="$t(`common.search`)"
      back-location="Search"
      :subtitle="$t(pageType === 'NS' ? `common.networkStatements` : `common.corridorInformationDocuments`)"
    />
    <div v-if="pageType && hasSavedSearches" class="mb-5">
      <div class="col-2 saved-search pl-0 mb-2">{{ $t(`search.savedSearches`) }}:</div>
      <div class="row">
        <div
          v-for="(search, index) in savedSearchesForType(pageType).slice(0, 3)"
          :key="index"
          class="search_box ml-2 col-2"
        >
          <div class="saved_search" @click.prevent="selectSavedSearch(search)">
            <div v-if="search.searchParameters.type === 'NS'" class="document_type_ns"><img :src="nsSearch" /></div>
            <div v-if="search.searchParameters.type === 'CID'" class="document_type_cid"><img :src="cidSearch" /></div>
            <span class="search_name">{{ search.name }}</span>
          </div>
        </div>
        <button class="search_box button-search-view-all view-all" @click="openSavedSearch">
          {{ 'View all' }}
        </button>
      </div>
    </div>
    <div id="inputs" class="row">
      <div v-if="pageType === 'NS'" class="col-md-3 col-sm-6">
        <MultipleSelectComponent
          v-model="selectedIMs"
          :tabindex="4"
          :options="imTypes"
          :name="$t(`common.infrastructureManagers`)"
          obj-key="name"
          obj-val="value"
          class="multiselect-big"
          @change="didChangeIMSelection"
        />
      </div>
      <div v-if="pageType !== 'NS'" class="col-md-3 col-sm-6">
        <MultipleSelectComponent
          v-model="selectedRFCs"
          :tabindex="4"
          :options="rfcTypes"
          :name="$t(`common.railFreightCorridors`)"
          obj-key="name"
          obj-val="value"
          class="multiselect-big"
          @change="didChangeRFCSelection"
        />
      </div>
      <div class="col-md-2 col-sm-4">
        <SelectComponent
          v-model="selectedCountry"
          class="selection-bold select-big country-select"
          :tabindex="1"
          :options="countries"
          :placeholder="selectedCountryName"
          :name="$t(`search.country`)"
          obj-key="name"
          obj-val="id"
          :object="true"
          :is-disabled="useDownloadView"
        />
      </div>
      <div class="col-md-3 col-sm-5">
        <InputField
          :name="$t(`search.freeSearch`)"
          :tabindex="5"
          :value.sync="text"
          :use-model="true"
          class="input-big"
          :enter-callback="gotoSearchResults"
          :is-disabled="useDownloadView"
          @update="text = $event"
        />
      </div>
      <div class="col-md-1 col-sm-7 form-group">
        <label data-v-5f97ac8e class="input-title">
          {{ $t(`search.includeAnnexes`) }}
        </label>
        <div class="switch_options_container-big with-background">
          <input v-model="includeAnnexes" type="checkbox" :disabled="useDownloadView" />
        </div>
      </div>
      <div class="col-md-1 col-sm-7 form-group">
        <label data-v-5f97ac8e class="input-title">
          {{ $t(`search.downloadWholeDocument`) }}
        </label>
        <div class="switch_options_container-big with-background">
          <input v-model="useDownloadView" type="checkbox" @click="toggleUseDownloadView" />
        </div>
      </div>
      <div v-if="!useDownloadView" class="col-md-2 col-sm-7 form-group">
        <label data-v-5f97ac8e class="input-title">&nbsp;</label>
        <button class="button-search btn-big w-100" @click="gotoSearchResults">
          {{ $t(`common.search`) }}
        </button>
      </div>
      <div v-if="useDownloadView" class="col-md-2 col-sm-7 form-group">
        <label data-v-5f97ac8e class="input-title">&nbsp;</label>
        <button class="button-download btn-big w-100" @click="downloadFullDocument">
          {{ $t(`common.download`) }}
        </button>
      </div>
    </div>
    <!-- div v-if="isLoggedIn" class="row">
      <div class="col-10">&nbsp;</div>
      <div class="col-2">
        <div class="content-right saved-searches-button">
          <a class="text-gray mb-1" @click.prevent="saveSearch">
            {{ $t(`search.saveSearch`) }}
          </a>
        </div>
      </div>
    </div -->
    <!-- Disclaimer -->
    <v-row align="center">
      <v-col v-if="pageType === 'NS'" cols="5" md="10" class="search-disclaimer">
        {{ $t(`search.disclaimerNS`) }}
      </v-col>
      <v-col v-if="pageType === 'CID'" cols="5" md="10" class="search-disclaimer">
        {{ $t(`search.disclaimerCID`) }}
      </v-col>
      <v-col cols="7" md="2">
        <div class="content-right select-deselect">
          <a class="text-gray" @click.prevent="clearAllFilters">
            {{ $t(`common.clearAllFilters`) }}
          </a>
        </div>
      </v-col>
    </v-row>
    <!-- Chapter tree -->
    <div v-if="!isLoading" class="row">
      <div class="col-12">
        <v-tabs v-model="activeTab" :center-active="true">
          <v-tab v-for="(year, index) in structureYears" :key="index" :href="`#tab-${year.id}`">
            <v-checkbox
              v-model="yearsSelected"
              class="mx-2"
              :value="year.id"
              color="#bf873d"
              @change="checkDeselectEvent($event, year.id)"
            />
            <!-- @change="selectDeselectBaseLevels($event, year.id)" -->
            {{ year.year }}
          </v-tab>
          <v-tab-item v-for="(year, index) in structureYears" :key="index" :value="'tab-' + year.id">
            <v-card v-if="!useDownloadView" class="m-0 p-0" flat tile>
              <v-card-text class="m-0 p-0">
                <div class="row">
                  <div class="col-9">
                    <v-treeview
                      ref="structure"
                      v-model="selectedChapters[year.id]"
                      class="mt-5"
                      :items="structureYears[index].levels"
                      :open.sync="expandedNodes[year.id]"
                      item-children="levels"
                      item-key="id"
                      item-text="nameLocal"
                      selection-type="leaf"
                      selectable
                      dense
                      multiple-active
                      selected-color="#bf873d"
                      @input="onSingleSelect($event, year.id)"
                    >
                      <template slot="label" slot-scope="{ item }">
                        <span v-if="!item.parentId && !item.isBook && false">
                          <strong>{{ $t(`common.book`) }}</strong>
                        </span>
                        <span v-if="item.parentId">{{ item.csNumber }}</span>
                        {{ item.nameLocal || item.nameEn }}
                      </template>
                    </v-treeview>
                  </div>
                  <div class="col-3 content-right select-deselect">
                    <a class="text-gold" @click.prevent="selectAll(year.id)">
                      {{ $t(`common.selectAll`) }}
                    </a>
                    <a class="text-gray" @click.prevent="clearAll(year.id)">
                      {{ $t(`common.clearAll`) }}
                    </a>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
      <router-view :get-search-info="getSearchInfo" />
    </div>
    <ManageSearchModal :is-modal-open="isModalOpen" :close-dialog="closeDialog" />
    <ManageSearchModal :is-modal-open="isSaveSearchOpen" :close-dialog="closeSavedSearchDialog" :search="searchInfo" />
    <ManageSearchModal
      :is-modal-open="isInfoSearchOpen"
      :close-dialog="closeInfoSearchDialog"
      :search="infoSearch"
      :is-info="true"
      style="z-index: 20001"
    />
    <SavedSearchesListModal
      v-if="hasSavedSearches"
      :is-modal-open="isSavedSearchOpen"
      :close-dialog="closeSavedSearchDialog"
      :saved-searches="savedSearches"
      :open-info="openInfo"
      :select-saved-search="selectSavedSearch"
    />
  </SearchLayout>
</template>

<script>
import Headings from '@/components/base/Headings';
import SearchLayout from '@/components/layout/SearchLayout';
import MultipleSelectComponent from '@/components/base/MultipleSelectComponent';
import InputField from '@/components/base/InputField';
import SelectComponent from '@/components/base/SelectComponent';
import { mapActions, mapState, mapMutations } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent';
import ManageSearchModal from '@/components/search/ManageSearchModal';
import nsSearch from '@/assets/icons/ns-search.svg';
import cidSearch from '@/assets/icons/cid-search.svg';
import SavedSearchesListModal from '@/components/search/SavedSearchesListModal';

export default {
  name: 'SearchStep2',
  components: {
    Headings,
    MultipleSelectComponent,
    InputField,
    SelectComponent,
    SearchLayout,
    LoadingComponent,
    ManageSearchModal,
    SavedSearchesListModal,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'SearchResults') {
      // Remove store data for search query
      this.clearSearch();
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== 'SearchResults' || (vm.lastQueryType !== '' && vm.lastQueryType !== to.params?.type)) {
        vm.resetState();
      }
      next();
    });
  },
  data() {
    return {
      nsSearch,
      cidSearch,
      pageType: null,
      selectedIMs: [],
      selectedRFCs: [],
      selectedCountry: {},
      selectedChapters: {},
      includeAnnexes: true,
      useDownloadView: false,
      organizationTypesArray: [],
      text: '',
      organizationTypeModel: null,
      chaptersLocal: {},
      yearsSelected: [],
      iterationDone: false,
      filteredChapters: [],
      isLoading: false,
      previouslySelected: [],
      activeTab: '',
      expandedNodes: {},
      isModalOpen: false,
      isSaveSearchOpen: false,
      isSavedSearchOpen: false,
      savedSearches: [],
      infoSearch: null,
      isInfoSearchOpen: false,
    };
  },
  computed: {
    ...mapState({
      structureYears: (state) => state.search.structureYears,
      currentTab: (state) => state.search.currentTab,
      lastQueryType: (state) => state.search.query.lastQuerySearchType,
      yearsDropdown: function (state) {
        return Object.keys(state.search.structureYears).map((item) => {
          return {
            value: state.search.structureYears[item].id,
            year: state.search.structureYears[item].year,
          };
        });
      },
      countries: (state) => {
        const updated = [...state.common.memberCountries].map((c) => {
          return {
            id: c.id,
            name: c.name,
            code: c.code,
            disabled: !c.hasDocumentsForCurrentYears,
          };
        });
        updated.splice(0, 0, {
          name: 'All',
          id: null,
          disabled: false,
        });
        return updated;
      },
      isLoggedIn: function () {
        return !window.localStorage.getItem('anonymous');
      },
      hasSavedSearches: function () {
        return this.isLoggedIn && this.savedSearchesForType(this.pageType).length > 0;
      },
      searchInfo: function () {
        if (!this || !this.pageType || !this.structureYears) {
          return;
        }

        const years = [];
        for (const year of this.structureYears) {
          if (this.yearsSelected.indexOf(year.id) >= 0) {
            years.push(year.year);
          }
        }
        const chapters = [];
        for (const year in this.selectedChapters) {
          const yearChapters = this.toChapterObjects(year, this.selectedChapters[year]);
          chapters.push(...yearChapters);
        }
        const result = {
          presentation: {
            type: this.pageType,
            ims: this.selectedIMs,
            rfcs: this.selectedRFCs,
            years: years,
            chapters: chapters,
            countries: this.countries,
          },
          data: {
            // name: searchName,
            type: this.pageType,
            selectedIMs: this.selectedIMs,
            selectedRFCs: this.selectedRFCs,
            selectedCountry: this.selectedCountry,
            yearsSelected: this.yearsSelected,
            selectedChapters: this.selectedChapters,
            chaptersLocal: this.chaptersLocal,
            filteredChapters: this.filteredChapters,
            includeAnnexes: this.includeAnnexes,
            useDownloadView: this.useDownloadView,
            text: this.text,
            organizationTypesArray: this.organizationTypesArray,
            organizationTypeModel: this.organizationTypeModel,
            iterationDone: this.iterationDone,
          },
        };
        return result;
      },
      imTypes: (state) => {
        return state.ims.data.map((im) => {
          return {
            value: im.id,
            name: im.name,
            disabled: !im.hasDocumentsForCurrentYears,
          };
        });
      },
      rfcTypes: (state) =>
        state.rfcs.data.map((rfc) => {
          return {
            value: rfc.id,
            name: rfc.name + ' ( ' + rfc.shortName + ' )',
            disabled: !rfc.hasDocumentsForCurrentYears,
          };
        }),
      query: (state) => state.search.query,
    }),
    selectedCountryName: function () {
      return this.selectedCountry?.name;
    },
    hasSavedSearches: function () {
      const isLoggedIn = !window.localStorage.getItem('anonymous');
      return isLoggedIn && this.savedSearchesForType(this.pageType).length > 0;
    },
    searchInfo: function () {
      if (!this || !this.pageType || !Array.isArray(this.structureYears)) {
        return;
      }

      const years = [];
      for (const year of this.structureYears) {
        if (this.yearsSelected.indexOf(year.id) >= 0) {
          years.push(year.year);
        }
      }
      const chapters = [];
      for (const year in this.selectedChapters) {
        const yearChapters = this.toChapterObjects(year, this.selectedChapters[year]);
        chapters.push(...yearChapters);
      }
      return {
        presentation: {
          type: this.pageType,
          ims: this.selectedIMs,
          rfcs: this.selectedRFCs,
          years: years,
          chapters: chapters,
          countries: this.countries,
        },
        data: {
          // name: searchName,
          type: this.pageType,
          selectedIMs: this.selectedIMs,
          selectedRFCs: this.selectedRFCs,
          selectedCountry: this.selectedCountry,
          yearsSelected: this.yearsSelected,
          selectedChapters: this.selectedChapters,
          chaptersLocal: this.chaptersLocal,
          filteredChapters: this.filteredChapters,
          includeAnnexes: this.includeAnnexes,
          useDownloadView: this.useDownloadView,
          text: this.text,
          organizationTypesArray: this.organizationTypesArray,
          organizationTypeModel: this.organizationTypeModel,
          iterationDone: this.iterationDone,
        },
      };
    },
  },
  watch: {
    '$route.params.type'(
      type // eslint-disable-line no-unused-vars
    ) {
      this.isLoading = true;
      this.pageType = this.$route.params.type;

      if (this.lastQueryType !== this.pageType) {
        this.setLastQuerySearchType(this.pageType);
        this.resetState();
      }

      this.getCommonStructureSearch({
        data: this.pageType,
        cb: () => {
          // this.clearSearch();
          // this.rehydrateQuery();
          this.isLoading = false;
        },
      });
    },
    useDownloadView: function (newValue) {
      if (newValue) {
        if (this.selectedIMs.length > 1) {
          this.selectedIMs = [];
        }

        if (this.selectedRFCs.length > 1) {
          this.selectedRFCs = [];
        }

        if (this.yearsSelected.length > 1) {
          this.yearsSelected.forEach((yearId) => {
            this.clearAll(yearId);
          });
          this.yearsSelected = [];
        }
      }
    },
  },
  created() {
    this.isLoading = true;
    this.getEntities({});
    this.getAllIMs({});
    this.getAllRFCs({});
    this.pageType = this.$route.params.type;
    this.activeTab = this.$route.query.activeTab || this.$route.params.activeTab || this.currentTab;

    this.getCommonStructureSearch({
      data: this.pageType,
      cb: () => {
        this.rehydrateQuery();
        this.getMemberCountries({ type: this.pageType });
        this.isLoading = false;
        const load_search = localStorage.getItem('load_search');
        if (load_search) {
          localStorage.setItem('load_search', '');
          this.selectSavedSearch(JSON.parse(load_search));
        }
      },
    });

    const anonymous = localStorage.getItem('anonymous');
    if (anonymous !== 'true') {
      this.fetchUserSearches({
        cb: (newValue) => {
          if (newValue) {
            this.savedSearches = newValue;
            for (const search of this.savedSearches) {
              search.searchParameters = JSON.parse(search.searchParameters);
            }
          }
          this.isLoading = false;
        },
        ecb: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
    }
  },
  methods: {
    ...mapActions('ims', ['getAllIMs']),
    ...mapActions('rfcs', ['getAllRFCs']),
    ...mapActions('common', ['getMemberCountries', 'getEntities']),
    ...mapActions('search', [
      'getCommonStructureSearch',
      'setQueryField',
      'setCurrentPage',
      'setSelectedItem',
      'downloadFullDocumentZip',
      'fetchUserSearches',
      'convertInfo',
    ]),
    ...mapMutations('search', [
      'clearSearch',
      'clearStructureYears',
      'clearSearchParams',
      'setLastQuerySearchType',
      'setCurrentTab',
    ]),
    getSearchInfo() {
      return this.searchInfo;
    },
    savedSearchesForType(type) {
      const result = [];
      for (const search of this.savedSearches) {
        if (search.searchParameters.type === type) {
          result.push(search);
        }
      }
      return result.reverse();
    },
    closeDialog() {
      this.isModalOpen = false;
    },
    closeSavedSearchDialog() {
      this.isSavedSearchOpen = false;
    },
    selectSavedSearch(item) {
      this.text = item.searchParameters.query;
      this.includeAnnexes = item.searchParameters.includeAnnexes;
      this.useDownloadView = item.searchParameters.useDownloadView;
      this.selectedCountry =
        item.searchParameters.countryIds.length && item.searchParameters.countryIds[0]
          ? item.searchParameters.countryIds[0].id
          : null;
      this.selectedIMs = item.searchParameters.imIds.map((e) => {
        return { name: e.name, value: e.id };
      });
      this.selectedRFCs = item.searchParameters.rfcIds.map((e) => {
        return { name: e.name, value: e.id };
      });
      this.yearsSelected = item.searchParameters.commonStructures.map((e) => e.csId);
      Object.keys(this.selectedChapters).forEach((key) => delete this.selectedChapters[key]);
      for (const cs of item.searchParameters.commonStructures) {
        if (cs.selectedChapters.length === 0) {
          this.selectFirst(cs.csId);
        } else {
          this.selectedChapters[cs.csId] = cs.selectedChapters.map((e) => e.id);
        }
      }

      this.isSavedSearchOpen = false;

      this.gotoSearchResults();
    },
    recursiveAdd(index, obj) {
      if (!this.selectedChapters[index]) {
        this.selectedChapters[index] = [];
      }
      this.selectedChapters[index].push(obj.id);

      if (!this.chaptersLocal[index]) {
        this.chaptersLocal[index] = { selectedChapters: [] };
      }

      if (!this.chaptersLocal[index].selectedChapters.includes(obj.id)) {
        this.chaptersLocal[index].selectedChapters.push(obj.id);
      }

      if (!obj.levels) {
        return;
      }

      obj.levels.forEach((child) => this.recursiveAdd(index, child));
    },
    checkDeselectEvent(event, yearId) {
      if (this.yearsSelected.indexOf(yearId) === -1) {
        // year has been deselected - invoke clear all
        this.clearAll(yearId);
      } else {
        this.selectFirst(yearId);
      }

      // prevent multiple years on download action
      if (this.useDownloadView) {
        while (this.yearsSelected.length > 1) {
          this.yearsSelected.shift();
        }
      }
    },
    onSingleSelect(event, yearId) {
      // select parent year
      if (event.length && this.yearsSelected.indexOf(yearId) === -1) {
        this.yearsSelected.push(yearId);
      }
      if (!this.chaptersLocal[yearId]) {
        this.chaptersLocal[yearId] = { selectedChapters: [] };
      }
      this.chaptersLocal[yearId].selectedChapters = event;
      if (this.previouslySelected[yearId]) {
        this.previouslySelected = this.previouslySelected[yearId].filter((s) => !event.includes(s));
      }
    },
    selectDeselectBaseLevels(event, yearId) {
      if (!event || !event.length || !event.includes(yearId)) {
        this.clearAll(yearId);
      } else {
        this.selectAll(yearId);
      }
    },
    selectAll(index) {
      this.clearAll(index);
      const yearIndex = this.structureYears.findIndex((o) => o.id === index);
      this.structureYears[yearIndex].levels.forEach((obj) => this.recursiveAdd(index, obj));
      this.previouslySelected[index] = [];
      this.$forceUpdate();
    },
    clearAll(index) {
      if (!this.chaptersLocal[index]) {
        this.chaptersLocal[index] = { selectedChapters: [] };
      }
      this.chaptersLocal[index].selectedChapters = [];
      if (!this.selectedChapters[index]) {
        this.selectedChapters[index] = [];
      }
      this.selectedChapters[index] = [];
      this.previouslySelected[index] = [];
      this.$forceUpdate();
    },
    selectFirst(index) {
      this.clearAll(index);
      const yearIndex = this.structureYears.findIndex((o) => o.id === index);
      this.recursiveAdd(index, this.structureYears[yearIndex].levels[0]);
      this.previouslySelected[index] = [];
      this.$forceUpdate();
    },
    didChangeIMSelection() {
      if (this.useDownloadView) {
        while (this.selectedIMs.length > 1) {
          this.selectedIMs.shift(this.selectedIMs.length - 1);
        }
      }
    },
    didChangeRFCSelection() {
      if (this.useDownloadView) {
        while (this.selectedRFCs.length > 1) {
          this.selectedRFCs.shift();
        }
      }
    },
    toChapterObjectsFromLevel(parent, chapterIds, result) {
      let count = 0;
      for (const chapter of parent.levels) {
        if (chapterIds.indexOf(chapter.id) >= 0 && !chapter.levels.length) {
          count++;
          result.push(chapter);
        } else if (chapter.levels) {
          const temp_result = [];
          const added = this.toChapterObjectsFromLevel(chapter, chapterIds, temp_result);
          if (added && added === chapter.levels.length) {
            count++;
            result.push(chapter);
          } else if (temp_result.length) {
            result.push(...temp_result);
          }
        }
      }
      return count;
    },
    toChapterObjects(yearId, chapterIds) {
      const result = [];
      for (const year of this.structureYears) {
        if (year.id === yearId) {
          for (const chapter of year.levels) {
            if (chapterIds.indexOf(chapter.id) >= 0 && !chapter.levels.length) {
              result.push(chapter);
            } else if (chapter.levels) {
              const temp_result = [];
              const added = this.toChapterObjectsFromLevel(chapter, chapterIds, temp_result);
              if (added && added === chapter.levels.length) {
                result.push(chapter);
              } else if (temp_result.length) {
                result.push(...temp_result);
              }
            }
          }
        }
      }
      return result;
    },
    saveSearch() {
      this.isSaveSearchOpen = true;
    },
    openSavedSearch() {
      this.isSavedSearchOpen = true;
    },
    showSavedSearches() {
      this.isModalOpen = true;
    },
    renameSavedSearch(search, index) {
      const newName = prompt('Rename search', search.name);
      if (newName) {
        this.savedSearches[index].name = newName;
        localStorage.setItem('savedSearches', JSON.stringify(this.savedSearches));
      }
    },
    useSavedSearch(search) {
      this.selectedIMs = search.selectedIMs;
      this.selectedRFCs = search.selectedRFCs;
      this.selectedCountry = search.selectedCountry;
      this.selectedChapters = search.selectedChapters;
      this.includeAnnexes = search.includeAnnexes;
      this.useDownloadView = search.useDownloadView;
      this.organizationTypesArray = search.organizationTypesArray;
      this.text = search.text;
      this.organizationTypeModel = search.organizationTypeModel;
      this.chaptersLocal = search.chaptersLocal;
      this.yearsSelected = search.yearsSelected;
      this.iterationDone = search.iterationDone;
      this.filteredChapters = search.filteredChapters;

      this.isModalOpen = false;

      this.gotoSearchResults();
    },
    rehydrateQuery() {
      this.selectedIMs = this.query.ims ? this.imTypes.filter((im) => this.query.ims.includes(im.value)) : [];
      this.selectedRFCs = this.query.rfcs ? this.rfcTypes.filter((rfc) => this.query.rfcs.includes(rfc.value)) : [];
      this.selectedCountry = this.query.country;
      this.text = this.query.text;
      this.useDownloadView = this.query.useDownloadView;
      this.includeAnnexes = this.query.includeAnnexes;
      this.yearsSelected = this.query.years.slice();
      this.chaptersLocal = JSON.parse(JSON.stringify(this.query.chaptersLocal));
      const arr = [];
      if (this.query.selectedChapters.length) {
        this.query.selectedChapters.forEach((ch) => {
          arr[ch.csId] = [];
          arr[ch.csId].push(...ch.selectedChapters);
        });
      }
      this.selectedChapters = Object.assign({}, arr);
      this.previouslySelected = Object.assign({}, arr);
    },
    findItemNested(arr, itemId) {
      return arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item.levels) return this.findItemNested(item.levels, itemId);
        return null;
      }, null);
    },
    sortValues(uniqueParents, cloneUnique) {
      if (this.iterationDone) return;
      // eslint-disable-next-line no-unused-vars
      uniqueParents.forEach((children, parent) => {
        if (this.iterationDone) return;
        const parentObj = this.findItemNested(this.structureYears, parent);
        const parentLevels = parentObj.levels;
        let areTheSame = true;
        parentLevels.forEach((level) => {
          if (!children.includes(level.id)) {
            areTheSame = false;
          }
        });
        if (areTheSame) {
          if (parentObj.parentId) {
            if (!cloneUnique.has(parentObj.parentId)) {
              cloneUnique.set(parentObj.parentId, []);
            }
            cloneUnique.get(parentObj.parentId).push(parent);
            cloneUnique.delete(parent);
            uniqueParents.forEach((children, parent) => {
              uniqueParents.set(
                parent,
                children
                  .filter((v, i, a) => a.indexOf(v) === i)
                  .sort()
                  .reverse()
              );
            });
            // uniqueParents = new Map([...cloneUnique.entries()]);

            this.sortValues(uniqueParents, cloneUnique);
          } else if (parentObj.isBook) {
            cloneUnique.set(parentObj.id, []);
            cloneUnique.get(parentObj.id).push(parentObj.id);
            // this.iterationDone = true;

            // top-level parent condition passed - everything is selected
          }
        }
      });
    },
    getAllParentsIdMap(collection, yearId) {
      const valMap = new Map();
      collection.forEach((item) => {
        if (this.previouslySelected[yearId] && this.previouslySelected[yearId].includes(item)) return;
        const parentId = this.findItemNested(this.structureYears, item).parentId;
        if (!parentId) return valMap;
        if (!valMap.has(parentId)) {
          valMap.set(parentId, []);
        }
        valMap.get(parentId).push(item);
      });
      return valMap;
    },
    resetState() {
      this.clearStructureYears();
      // pageType: null,
      this.selectedIMs = [];
      this.selectedRFCs = [];
      // this.selectedCountry = {};
      this.selectedChapters = {};
      this.includeAnnexes = true;
      this.useDownloadView = false;
      this.organizationTypesArray = [];
      this.text = '';
      this.organizationTypeModel = null;
      this.chaptersLocal = {};
      this.yearsSelected = [];
      this.iterationDone = false;
      this.filteredChapters = [];
      // this.isLoading: false
      // this.clearAll();
      this.clearSearch();
      this.clearSearchParams();

      // restore values from the URL query parameters
      try {
        const chapters = JSON.parse(atob(this.$route.query.cs));
        this.setQueryField({
          prop: 'selectedChapters',
          value: chapters,
        });
        this.setQueryField({
          prop: 'years',
          value: chapters.map((item) => item.year).filter((value, index, self) => self.indexOf(value) === index),
        });
        this.setQueryField({
          prop: 'ims',
          value: JSON.parse(atob(this.$route.query.ims)),
        });
        this.setQueryField({
          prop: 'rfcs',
          value: JSON.parse(atob(this.$route.query.rfcs)),
        });
        this.setQueryField({
          prop: 'country',
          value: this.$route.query.country,
        });
        this.setQueryField({
          prop: 'text',
          value: this.$route.query.text,
        });
        this.setQueryField({
          prop: 'includeAnnexes',
          value: this.$route.query.annexes === 'true',
        });
      } catch (err) {
        // JSON parsing error
      }
    },
    toggleUseDownloadView() {
      this.includeAnnexes = true;
    },
    gotoSearchResults() {
      if (this.structureYears) {
        this.expandedNodes = Object.fromEntries(this.structureYears.map((year) => [year.id, []]));
      }
      this.setCurrentTab(this.activeTab);

      this.setQueryField({
        prop: 'type',
        value: this.pageType,
      });
      this.setQueryField({
        prop: 'ims',
        value: this.selectedIMs.map((im) => im.value),
      });
      this.setQueryField({
        prop: 'rfcs',
        value: this.selectedRFCs.map((rfc) => rfc.value),
      });

      let finalValues = [];
      const chaptersToSend = [];

      let hasSelectedChapters = false;
      // eslint-disable-next-line no-unused-vars
      for (const yearId of Object.keys(this.selectedChapters)) {
        this.iterationDone = false;
        const chapters = this.selectedChapters[yearId];
        if (chapters.length > 0) {
          hasSelectedChapters = true;
        }
        const sorted = chapters.sort().reverse();
        const uniqueParents = this.getAllParentsIdMap(sorted, yearId);
        const cloneUnique = uniqueParents;
        this.sortValues(uniqueParents, cloneUnique);

        // eslint-disable-next-line no-unused-vars
        cloneUnique.forEach((children, parent) => {
          finalValues.push(...children);
        });
        if (this.previouslySelected[yearId]) {
          finalValues.push(...this.previouslySelected[yearId]);
        }

        this.iterationDone = false;

        chaptersToSend[yearId] = Object.assign({}, this.chaptersLocal[yearId]);
        chaptersToSend[yearId].selectedChapters = Array.from(new Set(finalValues));
        chaptersToSend[yearId].year = yearId;
        chaptersToSend[yearId].csId = yearId;
        finalValues = [];
      }

      if (!hasSelectedChapters) {
        this.$root.$emit('toast-error', this.$t(`search.noSelectedYearOrChapter`));
        return;
      }

      const years = this.yearsSelected || [];
      const tempChapters = [];
      Object.keys(chaptersToSend).forEach((item) => {
        if (years.includes(item)) {
          tempChapters.push(chaptersToSend[item]);
        }
      });

      this.setQueryField({
        prop: 'country',
        value: this.selectedCountry,
      });
      this.setQueryField({
        prop: 'text',
        value: this.text,
      });
      this.setQueryField({
        prop: 'yearsDropdown',
        value: this.yearsDropdown,
      });
      this.setQueryField({
        prop: 'years',
        value: this.yearsSelected,
      });
      this.setQueryField({
        prop: 'chaptersLocal',
        value: this.chaptersLocal,
      });
      this.setQueryField({
        prop: 'chapters',
        value: tempChapters,
      });
      this.setQueryField({
        prop: 'selectedChapters',
        value: tempChapters,
      });
      this.setQueryField({
        prop: 'includeAnnexes',
        value: this.includeAnnexes,
      });
      this.setQueryField({
        prop: 'activeTab',
        value: this.activeTab,
      });

      const imsIds = this.selectedIMs.map((im) => im.value);
      const rfcIds = this.selectedRFCs.map((rfc) => rfc.value);
      const cs = btoa(JSON.stringify(tempChapters));
      const ims = btoa(JSON.stringify(imsIds));
      const rfcs = btoa(JSON.stringify(rfcIds));
      const yd = btoa(JSON.stringify(this.yearsDropdown));

      this.setCurrentPage(0);
      this.setSelectedItem(null);

      this.$router.push({
        name: 'SearchResults',
        params: {
          pageType: this.pageType,
          activeTab: this.activeTab,
        },
        query: {
          type: this.pageType,
          country: this.selectedCountry || '',
          annexes: this.includeAnnexes,
          text: this.text,
          cs,
          ims,
          rfcs,
          yearsDropdown: yd,
          activeTab: this.activeTab,
          t: Date.now(),
        },
      });
    },
    downloadFullDocument() {
      const years = this.yearsSelected || [];
      if (this.yearsSelected.length === 0) {
        this.$root.$emit('toast-error', this.$t(`search.documentDownloadErrorYearNotSelected`));
        return;
      }

      if (this.pageType === 'NS' && this.selectedIMs.length === 0) {
        this.$root.$emit('toast-error', this.$t(`search.documentDownloadErrorIMNotSelected`));
        return;
      }

      if (this.pageType === 'CID' && this.selectedRFCs.length === 0) {
        this.$root.$emit('toast-error', this.$t(`search.documentDownloadErrorRFCNotSelected`));
        return;
      }

      const selectedYear = this.structureYears.filter((year) => year.id === years[0])[0];
      const commonStructureId = selectedYear.id;
      const year = selectedYear.year;

      const im = this.selectedIMs.length === 0 ? null : this.selectedIMs[0];
      const rfc = this.selectedRFCs.length === 0 ? null : this.selectedRFCs[0];
      const imName = im && im.name;
      const rfcName = rfc && rfc.name;

      const data = {
        imId: im && im.value,
        imName: imName,
        rfcId: rfc && rfc.value,
        rfcName: rfcName,
        commonStructureId: commonStructureId,
        year: year,
        bookIds: null,
      };

      this.setLoading(true);
      this.downloadFullDocumentZip({
        data,
        cb: () => {
          this.setLoading(false);
        },
        ecb: () => {
          this.setLoading(false);
          this.$root.$emit(
            'toast-error',
            this.$t(`search.documentDownloadErrorDocumentNotFound`, {
              documentType: this.pageType,
              organization: imName || rfcName,
              year: year,
            })
          );
        },
      });
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    clearAllFilters() {
      this.selectedIMs = [];
      this.selectedRFCs = [];
      this.selectedCountry = {};
      this.text = '';
      this.includeAnnexes = true;
      this.yearsSelected = [];
      this.selectedChapters = {};
    },
    async openInfo(item) {
      this.infoSearch = await this.convertInfo(item);
      this.isInfoSearchOpen = true;
    },
    closeInfoSearchDialog() {
      this.isInfoSearchOpen = false;
    },
  },
};
</script>

<style scoped>
.slider {
  background-color: transparent;
}

.slider::before {
  transform: translateX(15px);
}

.slider.active {
  background-color: rgb(198, 241, 184);
}

.slider.active::before {
  transform: translateX(0px);
}

>>> .multiselect-big > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > input {
  min-height: 54px !important;
  max-height: unset;
  border: none !important;
  height: auto;
  padding: 10px;
}

.search-disclaimer {
  font-style: italic;
  font-size: 1.2em;
}

.btn-big {
  height: 54px;
}

.saved-searches-button {
  font-size: 1.25em;
}

.saved-searches-button a {
  text-decoration: underline;
}

.search_box {
  position: relative;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  padding: 4px;
  height: 46px;
  display: flex;
  align-items: center;
}

.saved_search {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  overflow: hidden;
}

.search_box .search_name {
  display: block;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

.search_name {
  font-size: 1.4em;
  line-height: 1.4em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search_box .document_type_ns,
.search_box .document_type_cid {
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid;
}

.search_box .document_type_ns img,
.search_box .document_type_cid img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.search_box .document_type_ns {
  background: #265f14;
}

.search_box .document_type_cid {
  background: #bf873d;
}

.view-all {
  border-color: #265f14;
  color: #265f14;
}

.saved-search {
  font-family: Roboto, sans-serif;
  font-size: 1.4em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
}
</style>
