var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SearchLayout",
    [
      _c("LoadingComponent", {
        attrs: { loading: _vm.isLoading },
        on: {
          "update:loading": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _c("Headings", {
        staticClass: "d-inline-block",
        attrs: {
          title: _vm.$t(`common.search`),
          "back-location": "Search",
          subtitle: _vm.$t(
            _vm.pageType === "NS"
              ? `common.networkStatements`
              : `common.corridorInformationDocuments`
          ),
        },
      }),
      _vm.pageType && _vm.hasSavedSearches
        ? _c("div", { staticClass: "mb-5" }, [
            _c("div", { staticClass: "col-2 saved-search pl-0 mb-2" }, [
              _vm._v(_vm._s(_vm.$t(`search.savedSearches`)) + ":"),
            ]),
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(
                  _vm.savedSearchesForType(_vm.pageType).slice(0, 3),
                  function (search, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "search_box ml-2 col-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "saved_search",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectSavedSearch(search)
                              },
                            },
                          },
                          [
                            search.searchParameters.type === "NS"
                              ? _c("div", { staticClass: "document_type_ns" }, [
                                  _c("img", { attrs: { src: _vm.nsSearch } }),
                                ])
                              : _vm._e(),
                            search.searchParameters.type === "CID"
                              ? _c(
                                  "div",
                                  { staticClass: "document_type_cid" },
                                  [_c("img", { attrs: { src: _vm.cidSearch } })]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "search_name" }, [
                              _vm._v(_vm._s(search.name)),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _c(
                  "button",
                  {
                    staticClass: "search_box button-search-view-all view-all",
                    on: { click: _vm.openSavedSearch },
                  },
                  [_vm._v(" " + _vm._s("View all") + " ")]
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "row", attrs: { id: "inputs" } }, [
        _vm.pageType === "NS"
          ? _c(
              "div",
              { staticClass: "col-md-3 col-sm-6" },
              [
                _c("MultipleSelectComponent", {
                  staticClass: "multiselect-big",
                  attrs: {
                    tabindex: 4,
                    options: _vm.imTypes,
                    name: _vm.$t(`common.infrastructureManagers`),
                    "obj-key": "name",
                    "obj-val": "value",
                  },
                  on: { change: _vm.didChangeIMSelection },
                  model: {
                    value: _vm.selectedIMs,
                    callback: function ($$v) {
                      _vm.selectedIMs = $$v
                    },
                    expression: "selectedIMs",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.pageType !== "NS"
          ? _c(
              "div",
              { staticClass: "col-md-3 col-sm-6" },
              [
                _c("MultipleSelectComponent", {
                  staticClass: "multiselect-big",
                  attrs: {
                    tabindex: 4,
                    options: _vm.rfcTypes,
                    name: _vm.$t(`common.railFreightCorridors`),
                    "obj-key": "name",
                    "obj-val": "value",
                  },
                  on: { change: _vm.didChangeRFCSelection },
                  model: {
                    value: _vm.selectedRFCs,
                    callback: function ($$v) {
                      _vm.selectedRFCs = $$v
                    },
                    expression: "selectedRFCs",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-md-2 col-sm-4" },
          [
            _c("SelectComponent", {
              staticClass: "selection-bold select-big country-select",
              attrs: {
                tabindex: 1,
                options: _vm.countries,
                placeholder: _vm.selectedCountryName,
                name: _vm.$t(`search.country`),
                "obj-key": "name",
                "obj-val": "id",
                object: true,
                "is-disabled": _vm.useDownloadView,
              },
              model: {
                value: _vm.selectedCountry,
                callback: function ($$v) {
                  _vm.selectedCountry = $$v
                },
                expression: "selectedCountry",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-3 col-sm-5" },
          [
            _c("InputField", {
              staticClass: "input-big",
              attrs: {
                name: _vm.$t(`search.freeSearch`),
                tabindex: 5,
                value: _vm.text,
                "use-model": true,
                "enter-callback": _vm.gotoSearchResults,
                "is-disabled": _vm.useDownloadView,
              },
              on: {
                "update:value": function ($event) {
                  _vm.text = $event
                },
                update: function ($event) {
                  _vm.text = $event
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-1 col-sm-7 form-group" }, [
          _c(
            "label",
            { staticClass: "input-title", attrs: { "data-v-5f97ac8e": "" } },
            [_vm._v(" " + _vm._s(_vm.$t(`search.includeAnnexes`)) + " ")]
          ),
          _c(
            "div",
            { staticClass: "switch_options_container-big with-background" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.includeAnnexes,
                    expression: "includeAnnexes",
                  },
                ],
                attrs: { type: "checkbox", disabled: _vm.useDownloadView },
                domProps: {
                  checked: Array.isArray(_vm.includeAnnexes)
                    ? _vm._i(_vm.includeAnnexes, null) > -1
                    : _vm.includeAnnexes,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.includeAnnexes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.includeAnnexes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.includeAnnexes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.includeAnnexes = $$c
                    }
                  },
                },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 col-sm-7 form-group" }, [
          _c(
            "label",
            { staticClass: "input-title", attrs: { "data-v-5f97ac8e": "" } },
            [_vm._v(" " + _vm._s(_vm.$t(`search.downloadWholeDocument`)) + " ")]
          ),
          _c(
            "div",
            { staticClass: "switch_options_container-big with-background" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.useDownloadView,
                    expression: "useDownloadView",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.useDownloadView)
                    ? _vm._i(_vm.useDownloadView, null) > -1
                    : _vm.useDownloadView,
                },
                on: {
                  click: _vm.toggleUseDownloadView,
                  change: function ($event) {
                    var $$a = _vm.useDownloadView,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.useDownloadView = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.useDownloadView = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.useDownloadView = $$c
                    }
                  },
                },
              }),
            ]
          ),
        ]),
        !_vm.useDownloadView
          ? _c("div", { staticClass: "col-md-2 col-sm-7 form-group" }, [
              _c(
                "label",
                {
                  staticClass: "input-title",
                  attrs: { "data-v-5f97ac8e": "" },
                },
                [_vm._v(" ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button-search btn-big w-100",
                  on: { click: _vm.gotoSearchResults },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.search`)) + " ")]
              ),
            ])
          : _vm._e(),
        _vm.useDownloadView
          ? _c("div", { staticClass: "col-md-2 col-sm-7 form-group" }, [
              _c(
                "label",
                {
                  staticClass: "input-title",
                  attrs: { "data-v-5f97ac8e": "" },
                },
                [_vm._v(" ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button-download btn-big w-100",
                  on: { click: _vm.downloadFullDocument },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.download`)) + " ")]
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _vm.pageType === "NS"
            ? _c(
                "v-col",
                {
                  staticClass: "search-disclaimer",
                  attrs: { cols: "5", md: "10" },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`search.disclaimerNS`)) + " ")]
              )
            : _vm._e(),
          _vm.pageType === "CID"
            ? _c(
                "v-col",
                {
                  staticClass: "search-disclaimer",
                  attrs: { cols: "5", md: "10" },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`search.disclaimerCID`)) + " ")]
              )
            : _vm._e(),
          _c("v-col", { attrs: { cols: "7", md: "2" } }, [
            _c("div", { staticClass: "content-right select-deselect" }, [
              _c(
                "a",
                {
                  staticClass: "text-gray",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearAllFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`common.clearAllFilters`)) + " ")]
              ),
            ]),
          ]),
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "center-active": true },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _vm._l(_vm.structureYears, function (year, index) {
                        return _c(
                          "v-tab",
                          { key: index, attrs: { href: `#tab-${year.id}` } },
                          [
                            _c("v-checkbox", {
                              staticClass: "mx-2",
                              attrs: { value: year.id, color: "#bf873d" },
                              on: {
                                change: function ($event) {
                                  return _vm.checkDeselectEvent($event, year.id)
                                },
                              },
                              model: {
                                value: _vm.yearsSelected,
                                callback: function ($$v) {
                                  _vm.yearsSelected = $$v
                                },
                                expression: "yearsSelected",
                              },
                            }),
                            _vm._v(" " + _vm._s(year.year) + " "),
                          ],
                          1
                        )
                      }),
                      _vm._l(_vm.structureYears, function (year, index) {
                        return _c(
                          "v-tab-item",
                          { key: index, attrs: { value: "tab-" + year.id } },
                          [
                            !_vm.useDownloadView
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "m-0 p-0",
                                    attrs: { flat: "", tile: "" },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      { staticClass: "m-0 p-0" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-9" },
                                            [
                                              _c("v-treeview", {
                                                ref: "structure",
                                                refInFor: true,
                                                staticClass: "mt-5",
                                                attrs: {
                                                  items:
                                                    _vm.structureYears[index]
                                                      .levels,
                                                  open: _vm.expandedNodes[
                                                    year.id
                                                  ],
                                                  "item-children": "levels",
                                                  "item-key": "id",
                                                  "item-text": "nameLocal",
                                                  "selection-type": "leaf",
                                                  selectable: "",
                                                  dense: "",
                                                  "multiple-active": "",
                                                  "selected-color": "#bf873d",
                                                },
                                                on: {
                                                  "update:open": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.expandedNodes,
                                                      year.id,
                                                      $event
                                                    )
                                                  },
                                                  input: function ($event) {
                                                    return _vm.onSingleSelect(
                                                      $event,
                                                      year.id
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function ({ item }) {
                                                        return [
                                                          !item.parentId &&
                                                          !item.isBook &&
                                                          false
                                                            ? _c("span", [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        `common.book`
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ])
                                                            : _vm._e(),
                                                          item.parentId
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.csNumber
                                                                  )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.nameLocal ||
                                                                  item.nameEn
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.selectedChapters[
                                                      year.id
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.selectedChapters,
                                                      year.id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedChapters[year.id]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-3 content-right select-deselect",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-gold",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.selectAll(
                                                        year.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          `common.selectAll`
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "text-gray",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.clearAll(
                                                        year.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          `common.clearAll`
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("router-view", {
                attrs: { "get-search-info": _vm.getSearchInfo },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("ManageSearchModal", {
        attrs: {
          "is-modal-open": _vm.isModalOpen,
          "close-dialog": _vm.closeDialog,
        },
      }),
      _c("ManageSearchModal", {
        attrs: {
          "is-modal-open": _vm.isSaveSearchOpen,
          "close-dialog": _vm.closeSavedSearchDialog,
          search: _vm.searchInfo,
        },
      }),
      _c("ManageSearchModal", {
        staticStyle: { "z-index": "20001" },
        attrs: {
          "is-modal-open": _vm.isInfoSearchOpen,
          "close-dialog": _vm.closeInfoSearchDialog,
          search: _vm.infoSearch,
          "is-info": true,
        },
      }),
      _vm.hasSavedSearches
        ? _c("SavedSearchesListModal", {
            attrs: {
              "is-modal-open": _vm.isSavedSearchOpen,
              "close-dialog": _vm.closeSavedSearchDialog,
              "saved-searches": _vm.savedSearches,
              "open-info": _vm.openInfo,
              "select-saved-search": _vm.selectSavedSearch,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }