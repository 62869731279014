<template>
  <v-navigation-drawer
    id="nav"
    permanent
    fixed
    :mini-variant.sync="collapsed"
    mini-variant-width="80px"
    :expand-on-hover="expandOnHover"
  >
    <v-list-item class="px-2" two-line>
      <!--
      <v-list-item-avatar>
        <v-avatar color="#808080" rounded>
          <span class="white--text headline">{{ acronym }}</span>
        </v-avatar>
      </v-list-item-avatar>
      -->
      <v-list-item-icon class="menu-icon-container menu-item-container-home">
        <img src="@/assets/icons/home.svg" class="home-icon" @click.stop="goToHome" />
      </v-list-item-icon>
      <v-list-item-content class="items_title">
        <v-list-item-title id="user_nav_fname">{{ firstName }}</v-list-item-title>
        <v-list-item-subtitle id="user_nav_lname">{{ familyName }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-btn class="mr-5" icon @click.stop="collapsed = !collapsed">
        <v-icon size="36">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <!-- Language selector -->
    <v-menu offset-y nudge-bottom="4" origin="top left" transition="scale-transition">
      <template #activator="{ on }">
        <v-list-item class="px-2" v-on="on">
          <v-list-item-avatar v-if="currentLocale && currentLocale.flagUrl">
            <img :src="currentLocale.flagUrl" />
          </v-list-item-avatar>
          <v-list-item-action-text v-else>{{
            ((currentLocale || {}).locale || '').toUpperCase()
          }}</v-list-item-action-text>
          <v-list-item-title>{{ (currentLocale || {}).name }}</v-list-item-title>
        </v-list-item>
      </template>
      <v-list class="pa-0">
        <v-list-item v-for="lang in sortedLanguages" :key="lang.id" @click="changeLanguage(lang)">
          <v-list-item-avatar>
            <img v-if="lang.flagUrl" :src="lang.flagUrl" style="border: 1px solid #ddd" />
          </v-list-item-avatar>
          <v-list-item-content>{{ lang.name }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-divider />
    <div class="container_icons">
      <v-list nav dense class="scrollable">
        <v-list-item
          v-for="(item, index) in userMenuIcons"
          :key="index"
          :style="$route.path === item.link ? selectedMenuStyle : ''"
          color="white"
          class="item_menu up_items_menu"
          @click.stop="navigateTo(item)"
        >
          <v-list-item-icon class="menu-icon-container">
            <img :src="$route.path === item.link ? item.active_icon : item.icon" :title="item.title" />
          </v-list-item-icon>
          <v-list-item-title :style="item.titleStyle" class="items_title">{{ item.title }}</v-list-item-title>
        </v-list-item>
        <div class="bottom-icons">
          <v-list-item
            v-if="bottomMenuIcons.profile"
            class="item_menu"
            :style="$route.path === (bottomMenuIcons.profile || {}).link ? selectedMenuStyle : ''"
            @click.stop="navigateTo({ linkName: 'PersonalProfile' })"
          >
            <v-list-item-icon class="menu-icon-container">
              <img
                id="icons"
                :src="
                  (bottomMenuIcons.profile || {})[
                    $route.path === (bottomMenuIcons.profile || {}).link ? 'active_icon' : 'icon'
                  ]
                "
                :title="$t('profile_settings')"
              />
            </v-list-item-icon>
            <v-list-item-title class="items_title">{{ bottomMenuIcons.profile.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :style="$route.path === bottomMenuIcons.logout.link ? selectedMenuStyle : ''"
            class="item_menu"
            @click="logOut"
          >
            <v-list-item-icon class="menu-icon-container">
              <img :src="bottomMenuIcons.logout.icon" :title="$t('navigationDrawer.logout')" />
            </v-list-item-icon>
            <v-list-item-title class="items_title">{{ bottomMenuIcons.logout.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="item_menu" @click="isNavBarLocked">
            <v-list-item-icon class="menu-icon-container">
              <v-icon class="ml-0">{{ navLockIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="items_title">{{ $t('navigationDrawer.navbar_lock') }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <div class="bottom-nav">
        <v-list nav dense class="bottom-nav-content-wrapper">
          <v-list-item color="white" class="item_menu">
            <v-list-item-icon>
              <img class="logo-img" src="@/assets/icons/nav-logo.svg" alt />
            </v-list-item-icon>
            <v-list-item-title id="bottom_item" class="items_title">
              <strong>{{ $t('navigationDrawer.rne') }}</strong> {{ $t('navigationDrawer.nci') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <LoadingComponent :loading="loading" />
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent.vue';

export default {
  name: 'UserNavDrawer',
  components: {
    LoadingComponent,
  },
  data() {
    return {
      expandOnHover: true,
      userMenuIcons: [],
      bottomMenuIcons: {},
      navLockIcon: 'lock_open',
      collapsed: true,
      selectedMenuStyle: 'border-right: 1px solid #c1893a; border-radius: 0px',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      firstName: 'auth/firstName',
      familyName: 'auth/familyName',
      acronym: 'auth/acronym',
      role: 'auth/role',
    }),
    ...mapGetters('languages', ['getLocales']),
    ...mapState({
      rfcAccess: (state) => {
        return state.auth.current.roles === 'ADMIN' || state.auth.current.railFreightCorridorOverviews.length > 0;
      },
    }),
    currentLocale() {
      const current = this.$root.$i18n.locale;
      return this.getLocales.find((item) => item.locale === current);
    },
    sortedLanguages() {
      const current = this.$root.$i18n.locale;
      return this.getLocales
        .filter((item) => item.locale !== current)
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? +1 : 0));
    },
    icons() {
      return [
        {
          link: '/search',
          icon: require('@/assets/icons/grey-icons/search.svg'),
          active_icon: require('@/assets/icons/gold-icons/search.svg'),
          title: this.$t('common.search'),
          visibleFor: null,
        },
        {
          link: '/documents',
          icon: require('@/assets/icons/grey-icons/document-mng.svg'),
          active_icon: require('@/assets/icons/gold-icons/document-mng.svg'),
          title: this.$t('common.documentManagement'),
          visibleFor: ['ADMIN', 'DOCUMENT_MANAGER'],
        },
        {
          link: '/common',
          icon: require('@/assets/icons/grey-icons/common-struct.svg'),
          active_icon: require('@/assets/icons/gold-icons/common-struct.svg'),
          title: this.$t('common.commonStructures'),
          visibleFor: ['ADMIN'],
        },
        {
          link: '/rfcs',
          linkName: 'RFCsList',
          icon: require('@/assets/icons/grey-icons/im-rfc.svg'),
          active_icon: require('@/assets/icons/gold-icons/im-rfc.svg'),
          title: this.$t('common.ImAndRfcManagement'),
          visibleFor: ['ADMIN', 'DOCUMENT_MANAGER'],
          condition: {
            hasAccess: 'rfc',
            redirectTo: '/ims',
          },
        },
        {
          link: '/countries',
          icon: require('@/assets/icons/grey-icons/refdata.svg'),
          active_icon: require('@/assets/icons/gold-icons/refdata.svg'),
          title: this.$t('navigationDrawer.country_management'),
          visibleFor: ['ADMIN'],
        },
        {
          link: '/tags',
          icon: require('@/assets/icons/grey-icons/tags.svg'),
          active_icon: require('@/assets/icons/gold-icons/tags.svg'),
          title: this.$t('tags.manage_tags'),
          visibleFor: ['ADMIN'],
        },
        {
          link: '/users',
          icon: require('@/assets/icons/grey-icons/users.svg'),
          active_icon: require('@/assets/icons/gold-icons/users.svg'),
          title: this.$t('users.users'),
          visibleFor: ['ADMIN'],
        },
        {
          link: '/statistics',
          icon: require('@/assets/icons/grey-icons/analytics.svg'),
          active_icon: require('@/assets/icons/gold-icons/analytics.svg'),
          title: this.$t('navigationDrawer.statistics'),
          visibleFor: ['ADMIN', 'REGISTERED_USER', 'DOCUMENT_MANAGER'],
        },
        {
          link: '/audit-log',
          icon: require('@/assets/icons/grey-icons/audit-log.svg'),
          active_icon: require('@/assets/icons/gold-icons/audit-log.svg'),
          title: this.$t('navigationDrawer.audit_log'),
          visibleFor: ['ADMIN'],
        },
        {
          link: '/languages',
          icon: require('@/assets/icons/grey-icons/globe.svg'),
          active_icon: require('@/assets/icons/gold-icons/globe.svg'),
          title: this.$t('navigationDrawer.translation'),
          visibleFor: ['ADMIN', 'TRANSLATOR'],
        },
      ];
    },
    userIcons() {
      return {
        settings_obj: {
          link: '/profile',
          linkName: 'PersonalProfile',
          icon: require('@/assets/icons/grey-icons/settings.svg'),
          active_icon: require('@/assets/icons/gold-icons/settings.svg'),
          title: this.$t('navigationDrawer.profile_settings'),
          visibleFor: ['ADMIN', 'REGISTERED_USER', 'DOCUMENT_MANAGER'],
        },
        logout_obj: {
          link: '/logout',
          icon: require('@/assets/icons/grey-icons/logout.svg'),
          active_icon: require('@/assets/icons/gold-icons/logout.svg'),
          title: this.$t('navigationDrawer.logout'),
          visibleFor: ['ADMIN', 'REGISTERED_USER', 'DOCUMENT_MANAGER'],
        },
      };
    },
  },
  beforeMount() {
    this.refreshLanguage();
    const navLock = window.localStorage.getItem('navLock') || true;
    this.expandOnHover = navLock === 'true' || navLock === true;
  },
  methods: {
    ...mapActions('auth', ['logOutRequest']),
    ...mapMutations('languages', ['setLocale']),
    isNavBarLocked() {
      this.navLockIcon = this.expandOnHover ? 'lock' : 'lock_open';
      this.expandOnHover = !this.expandOnHover;
      window.localStorage.setItem('navLock', this.expandOnHover);
    },
    goTo(link) {
      if (link) {
        this.$router.push({ path: link });
      }
    },
    navigateTo(item) {
      if (item.linkName) {
        this.$router.push({ name: item.linkName });
      } else {
        this.goTo(item.link);
      }
    },
    goToHome() {
      if (!this.role) {
        this.logOut();
      } else {
        this.$router.push({
          name: 'Search',
        });
      }
    },
    logOut() {
      this.logOutRequest(() => {
        window.localStorage.removeItem('sessionID');
        this.$router.push({
          name: 'LandingPage',
        });
      });
    },
    checkAccessCondition(condition) {
      return condition === 'rfc' ? this.rfcAccess : true;
    },
    changeLanguage(lang) {
      this.loading = true;
      this.$axios
        .get(`languages/${lang.id}/translations`)
        .then((response) => {
          this.$root.$i18n.setLocaleMessage(lang.locale, response);
          this.$root.$i18n.locale = lang.locale;
          this.setLocale(lang.locale);
          this.refreshLanguage();
        })
        .catch((err) => {
          this.$root.$emit('toast-error', err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshLanguage() {
      const currentUserRole = this.role;
      this.userMenuIcons = this.icons
        .filter((menuItem) => !menuItem.visibleFor || menuItem.visibleFor.includes(currentUserRole))
        .map((item) => {
          if (item.condition) {
            const cond = this.checkAccessCondition(item.condition.hasAccess);
            item.link = cond ? item.link : item.condition.redirectTo;
            item.linkName = item.linkName ? item.linkName : false;
          }
          return item;
        });
      this.bottomMenuIcons.logout = this.userIcons.logout_obj;
      this.bottomMenuIcons.profile = currentUserRole ? this.userIcons.settings_obj : null;
    },
  },
};
</script>

<style>
.items_title {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #404040;
  width: 100%;
}

.menu-item-container-home {
  margin-bottom: 0px !important;
  cursor: pointer;
}

.home-icon {
  width: 35px;
}

.v-list.v-sheet.v-sheet--tile.theme--light.v-list--dense.v-list--nav {
  padding: 0px;
}

.bottom-icons {
  position: absolute;
  bottom: 120px;
  width: 100%;
}

.bottom-nav {
  height: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #404040;
  margin-top: 20px;
}

.bottom-nav img {
  max-height: 80px;
  max-width: 80px;
  position: absolute;
  left: 8px;
  top: 13%;
}

.bottom-nav .v-list-item.theme--light {
  padding-left: 0px;
}

#user_nav_fname {
  color: #4c8939;
}

#user_nav_lname {
  color: #080808;
  font-weight: 600;
}

.material-icons {
  margin-left: 8px;
}

.bottom-nav-content-wrapper,
.bottom-nav-content-wrapper .v-list-item,
.bottom-nav-content-wrapper .v-list-item .v-list-item__icon {
  height: 100%;
}

#bottom_item {
  color: white;
  margin-left: 20px;
  font-size: 2.2em;
  overflow: visible;
}

.menu-icon-container {
  margin-right: 10px !important;
}

hr {
  margin-top: 5px;
}

@media screen and (max-height: 600px) {
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .bottom-nav {
    height: 50px !important;
  }

  .logo-img {
    max-height: 35px !important;
  }

  .bottom-icons {
    bottom: 55px !important;
  }
}

.v-list-item__avatar {
  justify-content: center !important;
}

.scrollable {
  max-height: calc(100% - 290px);
  overflow-y: auto;
}

.v-navigation-drawer--mini-variant .scrollable {
  overflow-y: hidden;
}

.v-navigation-drawer--mini-variant .up_items_menu {
  padding-left: 22px;
}

.container_icons {
  height: 80%;
}
</style>
