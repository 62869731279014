import { axiosInstance } from '../utils/axios.js';
import {
  GET_IMS_URL,
  GET_SINGLE_IMS_URL,
  UPDATE_SINGLE_IMS_URL,
  DELETE_SINGLE_IMS_URL,
  CREATE_IMS_URL,
  IMS_LOGO_URL,
} from '../utils/api.endpoints';

export const getAllIMsRequest = function (cb, ecb = null) {
  axiosInstance
    .get(GET_IMS_URL)
    .then((response) => {
      const ims = response;
      if (cb) cb(ims);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getIMRequest = function (id, cb, ecb = null) {
  axiosInstance
    .get(GET_SINGLE_IMS_URL(id))
    .then((response) => {
      const im = response;
      if (cb) cb(im);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateIMRequest = function (obj, cb, ecb = null) {
  const id = obj.id;
  const data = obj.data;

  const promises = [];
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // IM Update call
  promises.push(axiosInstance.put(UPDATE_SINGLE_IMS_URL(id), data));
  // Logo Update call\
  if (obj.logo) {
    const formData = new FormData();
    formData.append('logo', obj.logo);
    promises.push(axiosInstance.put(IMS_LOGO_URL(id), formData, config));
  }

  Promise.all(promises)
    .then((results) => {
      if (cb) cb(results);
    })
    .catch(() => {
      if (ecb) ecb();
    });
};

export const deleteIMRequest = function (id, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_SINGLE_IMS_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const uploadLogoRequest = function (data, cb, ecb = null) {
  const { id, logo } = data;
  const formData = new FormData();
  formData.append('logo', logo);
  axiosInstance
    .put(IMS_LOGO_URL(id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

// eslint-disable-next-line no-unused-vars
export const createIMRequest = function (obj, cb, ecb = null) {
  // Im call
  const data = obj.data;
  const formData = new FormData();
  formData.append('logo', obj.logo);
  axiosInstance
    .post(CREATE_IMS_URL, data)
    .then((im) => {
      if (im.error && ecb) {
        ecb(im.error);
      } else {
        const id = im.id;
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        // Logo call
        axiosInstance
          .put(IMS_LOGO_URL(id), formData, config)
          .then(() => {
            cb();
          })
          .catch(() => ecb());
      }
    })
    .catch(() => ecb());
};

export default {};
