import { axiosInstance } from '../utils/axios.js';
import {
  GET_RFCS_URL,
  GET_SINGLE_RFCS_URL,
  UPDATE_SINGLE_RFCS_URL,
  DELETE_SINGLE_RFCS_URL,
  CREATE_RFCS_URL,
  GRANT_ACCESS_FOR_USER,
  REVOKE_ACCESS_FOR_USER,
  RFCS_LOGO_URL,
} from '../utils/api.endpoints';

export const getAllRFCsRequest = function (cb, ecb = null) {
  axiosInstance
    .get(GET_RFCS_URL)
    .then((response) => {
      const rfcs = response;
      if (cb) cb(rfcs);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const getRFCRequest = function (id, cb, ecb = null) {
  axiosInstance
    .get(GET_SINGLE_RFCS_URL(id))
    .then((response) => {
      const rfc = response;
      if (cb) cb(rfc);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const updateRFCRequest = function (obj, cb, ecb = null) {
  const id = obj.id;
  const data = obj.data;
  const promises = [];
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  // RFC Update call
  promises.push(axiosInstance.put(UPDATE_SINGLE_RFCS_URL(id), data));
  // Logo Update call
  if (obj.logo) {
    const formData = new FormData();
    formData.append('logo', obj.logo);
    promises.push(axiosInstance.put(RFCS_LOGO_URL(id), formData, config));
  }

  Promise.all(promises)
    .then((results) => {
      console.log('promises ', promises);
      if (cb) cb(results);
    })
    .catch(() => {
      if (ecb) ecb();
    });
};

export const deleteRFCRequest = function (id, cb, ecb = null) {
  axiosInstance
    .delete(DELETE_SINGLE_RFCS_URL(id))
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const uploadLogoRequest = function (data, cb, ecb = null) {
  const { id, logo } = data;
  const formData = new FormData();
  formData.append('logo', logo);
  axiosInstance
    .put(RFCS_LOGO_URL(id), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const createRFCRequest = function (obj, cb, ecb = null) {
  const data = obj.data;
  axiosInstance
    .post(CREATE_RFCS_URL, data)
    .then((response) => {
      if (response.error && ecb) {
        ecb(response.error);
      } else {
        const formData = new FormData();
        formData.append('logo', obj.logo);
        axiosInstance
          .put(RFCS_LOGO_URL(response.id), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (cb) cb(response);
          })
          .catch((error) => {
            if (ecb) ecb(error);
            else console.log('Error', error);
          });
      }
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const grantAccessToUserRequest = function (data, cb, ecb = null) {
  const { userId, ...addFields } = data;
  axiosInstance
    .put(GRANT_ACCESS_FOR_USER(userId), addFields)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export const revokeAccessToUserRequest = function (data, cb, ecb = null) {
  const { userId, ...addFields } = data;
  axiosInstance
    .put(REVOKE_ACCESS_FOR_USER(userId), addFields)
    .then((response) => {
      if (cb) cb(response);
    })
    .catch((error) => {
      if (ecb) ecb(error);
      else console.log('Error', error);
    });
};

export default {};
