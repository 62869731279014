<template>
  <div>
    <LoadingComponent :loading="spin > 0" />
    <Headings
      class="d-inline-block mt-6"
      :subtitle="$t('search.search_results')"
      style="margin-bottom: 10px !important"
    />
    <!-- Disclaimer -->
    <div class="row search-disclaimer">
      <div class="col-12">
        {{ $t(`search.disclaimerResults`) }}
      </div>
    </div>
    <!-- Results -->
    <div class="row">
      <div class="col-xs-8 col-sm-12">
        <div class="btns-container">
          <button v-if="isLoggedIn" class="btn btn-green btn-medium mx-3" @click.prevent="saveSearchUI">
            {{ $t(`search.saveSearch`) }}
          </button>
          <button class="btn btn-green btn-medium mx-3" @click.prevent="download">
            {{ $t(`common.download`) }}
          </button>
          <span class="select-chapters-text">
            {{ $t('search.select_chapters') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-search-results">
          <table cellspacing="0">
            <thead class="table-head-shadow">
              <tr class="table-header-solid">
                <th style="width: 75px" />
                <th v-for="(header, index) in headers" :key="index">
                  <span class="header-text">{{ header.name }}</span>
                  <img :id="header.id" @click="sortBy(header.id)" />
                </th>
              </tr>
            </thead>
            <tbody v-if="currentResults.length">
              <tr v-for="(a, index) in currentResults" :id="a.documentToCSId" :key="index" class="table-clickable-row">
                <td>
                  <v-checkbox
                    v-model="selectedChapters"
                    class="mx-2"
                    :value="a.documentToCSId"
                    multiple
                    color="rgb(191, 135, 61)"
                  />
                </td>
                <td @click="open(a)">{{ a.organizationName }}</td>
                <td @click="open(a)">{{ a.year }}</td>
                <td @click="open(a)">{{ a.numberOfAnnexes }}</td>
                <td class="document-preview" @click="open(a)">
                  <strong>{{ a.csLevelName }}</strong>
                  <p>{{ a.documentPlainTextContent }}</p>
                </td>
                <td>
                  <v-icon class="copy-icon" @click="copyLink(a)">mdi-content-copy</v-icon>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!currentResults.length">
              <tr>
                <td v-if="spin === 0" colspan="6" class="no-data-col">
                  {{ noResultsMessage }}
                </td>
                <td v-else colspan="6" class="no-data-col">
                  {{ $t(`search.searching`) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Go back -->
    <div class="row">
      <div class="col-xs-8 col-sm-12 mt-5">
        <div class="btns-container">
          <button class="btn btn-green btn-medium mx-3" @click.prevent="download">
            {{ $t(`common.download`) }}
          </button>
          <span class="select-chapters-text">
            {{ $t('search.select_chapters') }}
          </span>
        </div>
      </div>
    </div>
    <ManageSearchModal :is-modal-open="isSaveSearchOpen" :close-dialog="closeSaveSearchDialog" :search="searchInfo" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import LoadingComponent from '@/components/base/LoadingComponent';
import Headings from '@/components/base/Headings';
import ManageSearchModal from '@/components/search/ManageSearchModal';

export default {
  name: 'SearchResults',
  components: {
    LoadingComponent,
    Headings,
    ManageSearchModal,
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (Object.keys(this.$route.query).length < 1) {
      this.$router.push({
        name: 'Search',
      });
      return;
    }
    this.page = 0;
    this.currentResults = [];
    this.onCreated();
    this.getAllSearchResults();
    this.loadPreviousPages();
  },
  beforeRouteLeave(to, from, next) {
    this.clearResults();
    next();
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    getSearchInfo: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      searchText: null,
      selectedCountry: null,
      selectedChapters: [],
      selectedIMs: [],
      selectedRFCs: [],
      selectedYears: [],
      includeAnnexes: true,
      chapters: {},
      currentResults: [],
      noResultsMessage: null,
      years: [],
      spin: 0,
      chaptersLocal: {},
      actualImsList: [],
      actualRfcsList: [],
      finalSearchObj: [],
      totalPages: 0,
      page: 0,
      totalElements: 0,
      isLoggedIn: false,
      isSaveSearchOpen: false,
      searchInfo: null,
    };
  },
  computed: {
    ...mapState({
      query: (state) => state.search.query,
      structureYears: (state) => state.search.structureYears,
      results: (state) => state.search.results,
      currentPage: (state) => state.search.currentPage,
      selectedItem: (state) => state.search.selectedItem,
      imTypes: (state) =>
        state.ims.data.map((im) => {
          return {
            value: im.id,
            name: im.name,
          };
        }),
      rfcTypes: (state) =>
        state.rfcs.data.map((rfc) => {
          return {
            value: rfc.id,
            name: rfc.name + ' ( ' + rfc.shortName + ' )',
          };
        }),
    }),
    headers() {
      return this.type === 'CID'
        ? [
            {
              name: 'RFC',
              id: 'name',
            },
            {
              name: this.$t('statistics.search_org.tt_year').toUpperCase(),
              id: 'shortName',
            },
            {
              name: this.$t('common.annexes').toUpperCase(),
              id: 'shortName',
            },
            {
              name: this.$t('documentManagement.document').toUpperCase(),
              id: 'shortName',
            },
            {
              name: this.$t('documentManagement.copyLink').toUpperCase(),
              id: 'copyLink',
            },
          ]
        : [
            {
              name: 'IM',
              id: 'name',
            },
            {
              name: this.$t('statistics.search_org.tt_year').toUpperCase(),
              id: 'shortName',
            },
            {
              name: this.$t('common.annexes').toUpperCase(),
              id: 'annexes',
            },
            {
              name: this.$t('documentManagement.document').toUpperCase(),
              id: 'document',
            },
            {
              name: this.$t('documentManagement.copyLink').toUpperCase(),
              id: 'copyLink',
            },
          ];
    },
  },
  created() {
    if (Object.keys(this.$route.query).length < 1) {
      this.$router.push({
        name: 'Search',
      });
      return;
    }
    this.onCreated();
    this.getAllSearchResults();
    this.loadPreviousPages();
    this.isLoggedIn = !window.localStorage.getItem('anonymous');
  },
  mounted() {
    window.addEventListener('scroll', this.visibilityChanged, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.visibilityChanged);
  },
  methods: {
    ...mapActions('search', [
      'setQueryField',
      'getSearchResults',
      'downloadSearchResults',
      'downloadFullDocumentZip',
      'setCurrent',
      'setCurrentPage',
      'setSelectedItem',
      'getCommonStructureSearch',
      'saveSearch',
    ]),
    ...mapActions('ims', ['getAllIMs']),
    ...mapActions('rfcs', ['getAllRFCs', 'getSingleRFCLogo', 'order']),
    ...mapMutations('search', ['setLastQuerySearchType', 'clearResults']),
    open(csLevel) {
      this.setCurrent(csLevel);
      this.setSelectedItem(csLevel.documentToCSId);
      this.$router.push({
        name: 'PreviewDocument',
        params: {
          type: this.type,
          orgID: csLevel.organizationId,
          year: csLevel.year,
          book: csLevel.bookName,
          chapterID: csLevel.chapterId,
          csNumber: csLevel.csNumber,
        },
      });
    },
    filterResultsBySelection(event) {
      this.spin++;
      const set = new Set(event.map((v) => v.value));
      this.currentResults = this.results.filter((r) => set.has(r.organizationId));
      this.spin--;
    },
    filterResultsByYear() {
      this.spin++;
      this.currentResults = this.results.filter((r) => this.selectedYears.find((s) => s.year === r.year));
      this.spin--;
    },
    sortBy(id) {
      this.order(id);
    },
    selectAll() {
      this.selectedChapters = this.results.map((item) => item.documentToCSId);
    },
    clearAll() {
      this.selectedChapters = [];
    },
    setBooksOnly() {
      this.finalSearchObj = [];
      const books = [];
      this.structureYears.forEach((stYear) => {
        const levels = stYear.levels;
        levels.forEach((l) => {
          if (l.isBook) books.push(l.id);
        });
      });
      this.chapters.forEach((ch) => {
        const tmpObj = Object.assign({}, ch);
        tmpObj.selectedRoots = [];
        ch.selectedChapters.forEach((c) => {
          if (books.includes(c)) {
            tmpObj.selectedRoots.push(c);
          }
        });
        tmpObj.selectedChapters = tmpObj.selectedChapters.filter((ch) => !tmpObj.selectedRoots.includes(ch));
        this.finalSearchObj.push(tmpObj);
      });
    },
    setCSs() {
      if (this.query.chapters.length > 0) {
        this.chapters = this.query.chapters;
      } else {
        if (!this.$route.query.cs) return;
        let cs;
        try {
          cs = JSON.parse(atob(this.$route.query.cs));
        } catch (err) {
          // JSON parsing error
          cs = {};
        }
        this.setQueryField({
          prop: 'chapters',
          value: cs,
        });
        this.chapters = [...cs];
      }

      if (this.$route.query.yearsDropdown) {
        let js;
        try {
          js = JSON.parse(atob(this.$route.query.yearsDropdown));
        } catch (err) {
          // JSON parsing error
          js = {};
        }
        this.years = js;
      }
      this.chapters.forEach((element) => {
        this.selectedYears.push(this.years.find((y) => y.value === element.year));
        // this.years.push(element.year);
      });

      if (this.query.chaptersLocal) {
        this.chaptersLocal = this.query.chaptersLocal;
      }
    },
    setIMs() {
      if (!this.imTypes || (this.imTypes && !this.imTypes.length)) {
        this.getAllIMs({
          cb: () => {
            this.filterIms();
          },
        });
      } else {
        this.filterIms();
      }
    },
    filterIms() {
      let filtered;
      if (this.query.ims.length > 0) {
        filtered = this.imTypes.filter((im) => this.query.ims.includes(im.value));
        // this.selectedIMs = [...this.query.ims];
      } else {
        if (!this.$route.query?.ims) return;
        let ims = atob(this.$route.query.ims);
        ims = JSON.parse(ims);
        this.setQueryField({
          prop: 'ims',
          value: ims,
        });
        // this.selectedIMs = ims;
        filtered = this.imTypes.filter((im) => ims.includes(im.value));
      }
      this.actualImsList = filtered;
      this.selectedIMs = filtered;
    },
    setRFCs() {
      if (!this.rfcTypes || (this.rfcTypes && !this.rfcTypes.length)) {
        this.getAllRFCs({
          cb: () => {
            this.filterRfcs();
          },
        });
      } else {
        this.filterRfcs();
      }
    },
    filterRfcs() {
      let filtered;
      const rfc = this.query.rfcs;
      if (rfc.length > 0) {
        // this.selectedRFCs = [...this.query.rfcs];
        filtered = this.rfcTypes.filter((item) => rfc.includes(item.value));
      } else {
        if (!this.$route.query.rfcs) return;
        let rfcs;
        try {
          rfcs = JSON.parse(atob(this.$route.query.rfcs));
        } catch (err) {
          // JSON parsing error
          rfcs = [];
        }
        this.setQueryField({
          prop: 'rfcs',
          value: rfcs,
        });
        // this.selectedRFCs = rfcs;
        filtered = this.rfcTypes.filter((rfc) => rfcs.includes(rfc.value));
      }
      this.actualRfcsList = filtered;
      this.selectedRFCs = filtered;
    },
    setCountry() {
      if (this.query.rfcs.length > 0) {
        this.selectedCountry = this.query.country;
      } else {
        const country = this.$route.query.country;
        this.setQueryField({
          prop: 'country',
          value: country,
        });
        this.selectedCountry = country;
      }
    },
    setText() {
      if (this.query.text.length > 0) {
        this.searchText = this.query.text;
      } else {
        const text = this.$route.query.text;
        this.setQueryField({
          prop: 'text',
          value: text,
        });
        this.searchText = text;
      }
    },
    setAnnexes() {
      if (this.query.includeAnnexes.length > 0) {
        this.includeAnnexes = !!this.query.includeAnnexes;
      } else {
        const annexes = this.$route.query.annexes;
        this.setQueryField({
          prop: 'includeAnnexes',
          value: annexes,
        });
        this.includeAnnexes = Boolean(annexes);
      }
    },
    visibilityChanged() {
      if (this.spin > 0) return;
      if (document.scrollingElement.scrollTop + window.innerHeight > document.scrollingElement.scrollHeight - 100) {
        if (this.totalPages > 0 && this.totalPages > this.page + 1 && this.totalElements > this.currentResults.length)
          this.loadMore();
      }
    },
    loadMore() {
      this.page++;
      if (process.env.NODE_ENV === 'development') console.log('Lazy loading page', this.page);
      this.getSearchRequest();
    },
    loadPreviousPages() {
      const intervalTimer = setInterval(() => {
        if (this.currentPage > this.page && this.spin === 0) {
          this.loadMore();
        } else if (this.page === this.currentPage && this.spin === 0) {
          if (this.selectedItem) {
            const el = document.getElementById(this.selectedItem);
            if (el) {
              setTimeout(() => {
                el.scrollIntoView({ behavior: 'smooth' });
              }, 1000);
            }
          }
          clearInterval(intervalTimer);
        }
      }, 100);
    },
    getSearchRequest() {
      this.spin++;
      const selIMs = this.selectedIMs.map((item) => item.value);
      const selRFCs = this.selectedRFCs.map((item) => item.value);
      const valueToSave = [];
      if (this.selectedCountry) {
        valueToSave.push(this.selectedCountry);
      }

      const data = {
        commonStructures: this.finalSearchObj,
        countryIds: valueToSave.length > 0 ? valueToSave : null,
        imIds: selIMs.length > 0 ? selIMs : null,
        includeAnnexes: this.includeAnnexes,
        query: this.searchText,
        rfcIds: selRFCs.length > 0 ? selRFCs : null,
        type: this.type,
      };
      this.getSearchResults({
        data,
        page: this.page,
        cb: (newValue) => {
          if (process.env.NODE_ENV === 'development') console.log('Search fetched lazily', newValue.content.length);
          this.currentResults.push(...newValue.content);
          this.noResultsMessage = null;
          this.totalPages = newValue.totalPages;
          this.totalElements = newValue.totalElements;
          this.page = newValue.number;
          if (!this.currentPage || this.currentPage === 0 || this.currentPage < newValue.number) {
            this.setCurrentPage(newValue.number);
          }
          if (this.query.ims.length === 0 && this.type === 'NS') {
            const uniqueIms = new Set();
            const ims = newValue.content.map((r) => {
              return {
                name: r.organizationName,
                value: r.organizationId,
              };
            });
            const filteredImList = ims.filter((im) => {
              if (uniqueIms.has(im.value)) return false;
              else {
                uniqueIms.add(im.value);
                return true;
              }
            });
            this.setQueryField({
              prop: 'ims',
              value: filteredImList.map((im) => im.value),
            });
            if (!this.query.chapters) {
              this.setQueryField({
                prop: 'chapters',
                value: this.chapters,
              });
            }
            if (!this.query.chaptersLocal) {
              this.setQueryField({
                prop: 'chaptersLocal',
                value: this.chaptersLocal,
              });
            }
            this.selectedIMs = [...filteredImList];
            this.actualImsList = [...filteredImList];
          }

          if (this.query.rfcs.length === 0 && this.type === 'CID') {
            const unique = new Set();
            const rfcs = newValue.content.map((r) => {
              return {
                name: r.organizationName,
                value: r.organizationId,
              };
            });
            const filteredList = rfcs.filter((rfc) => {
              if (unique.has(rfc.value)) return false;
              else {
                unique.add(rfc.value);
                return true;
              }
            });
            this.setQueryField({
              prop: 'rfcs',
              value: filteredList.map((rfc) => rfc.value),
            });
            this.selectedRFCs = [...filteredList];
            this.actualRfcsList = [...filteredList];
          }

          this.spin--;
          setTimeout(() => {
            this.visibilityChanged(); // check again if we are close to the bottom of the page - to try loading the next page
          }, 90);
        },
        ecb: (error) => {
          if (error.response.status === 404) {
            const err = error.response.data;
            const noResultKey = 'search.noResults.' + err.error;

            if (err.error === 'CHAPTER_MISSING') {
              console.log(err.details);
              this.noResultsMessage = this.$t(noResultKey, {
                chapter: err.details,
              });
            } else {
              this.noResultsMessage = this.$t(noResultKey);
            }
          }
          this.spin--;
        },
      });
    },
    getAllSearchResults() {
      this.spin++;
      if (!this.structureYears || (this.structureYears && !this.structureYears.length)) {
        this.getCommonStructureSearch({
          data: this.type,
          cb: () => {
            this.setBooksOnly();
            this.getSearchRequest();
            this.spin--;
          },
        });
      } else {
        this.setBooksOnly();
        this.getSearchRequest();
        this.spin--;
      }
    },
    download() {
      if (!this.selectedChapters.length) return;
      this.spin++;
      const searchResult = [];
      this.selectedChapters.forEach((i) => {
        const obj = this.results.find((res) => res.documentToCSId === i);
        if ((obj && obj.annexes === 'false') || (obj && !obj.annexes)) {
          obj.annexes = [];
        }
        const result = obj;
        const res = {
          csLevelId: result.csLevelId,
          csLevelName: result.csLevelName,
          documentId: result.documentId,
          documentToCSId: result.documentToCSId,
          organizationId: result.organizationId,
          organizationName: result.organizationName,
          year: result.year,
          annexes: result.annexes,
        };
        searchResult.push(res);
      });
      const data = {
        type: this.type,
        includeAnnexes: this.includeAnnexes,
        searchResult,
      };
      this.downloadSearchResults({
        data,
        cb: () => {
          this.spin--;
        },
        ecb: () => {
          this.spin--;
        },
      });
    },
    saveSearchUI() {
      this.searchInfo = this.getSearchInfo();
      this.isSaveSearchOpen = true;
    },
    closeSaveSearchDialog() {
      this.isSaveSearchOpen = false;
    },
    goBack() {
      this.$router.push({
        name: 'SearchStep2',
        params: {
          type: this.$route.query.type,
          activeTab: this.$route.query.activeTab,
        },
      });
    },
    onCreated() {
      this.$forceUpdate();
      this.setCSs();
      this.setIMs();
      this.setRFCs();
      this.setCountry();
      this.setText();
      this.setAnnexes();
      this.setLastQuerySearchType(this.$route.query.type);
    },
    toChapterObjectsFromLevel(parent, chapterIds, result) {
      if (!parent || !parent.levels) {
        return;
      }
      for (const chapter of parent.levels) {
        if (chapterIds.indexOf(chapter.id) >= 0) {
          if (chapterIds.indexOf(chapter.id) >= 0) {
            result.push(chapter);
          } else {
            this.toChapterObjectsFromLevel(chapter, chapterIds, result);
          }
        }
      }
    },
    toChapterObjects(yearId, chapterIds) {
      for (const year of this.structureYears) {
        if (year.id === yearId) {
          const result = [];
          for (const chapter of year.levels) {
            if (chapterIds.indexOf(chapter.id) >= 0) {
              result.push(chapter);
            } else {
              this.toChapterObjectsFromLevel(chapter, chapterIds, result);
            }
          }
          return result;
        }
      }
      console.log('Cannot find year in fetch data: ' + yearId);
      return null;
    },
    copyLink(a) {
      const url = this.$serverUrl.replace('api/', '');
      let link = '';
      if (this.type === 'NS') {
        link = `${url}/chapter-view?type=NS&year=${a.year}&organization=${a.organizationName.replace(' ', '+')}`;
      } else {
        const shortName = a.organizationName
          .substring(a.organizationName.indexOf('(') + 1)
          .split(')')[0]
          .replace(' ', '+');
        link = `${url}/chapter-view?type=CID&year=${a.year}&organization=${shortName}&book=${a.bookName.replace(
          ' ',
          '+'
        )}`;
      }
      if (a.csNumber) link += `&chapter=${a.csNumber}`;
      navigator.clipboard.writeText(link);
    },
  },
};
</script>

<style scoped>
>>> .v-select__selections {
  min-height: 56px;
}

>>> .v-select__selections input {
  /* display: none; */
  min-height: 0px;
  width: 0px !important;
  height: 0px;
  padding: 0px;
}

>>> .v-select.v-select--chips .v-select__selections {
  min-height: 56px;
}

select {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1.1em;
}

.btn-add-new-rfc {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 7px 5px;
  font-size: 1.4em;
  min-width: 140px;
  height: 42px;
  background-color: #265f14;
}

.btn-print {
  font-size: 1.6em;
}

.table-header-solid th {
  /* opacity: 1 !important; */
  /* background-color: #f8f8f8; */
  /* z-index: 999; */
  top: -1px;
}

table {
  border-collapse: collapse;
}

.table-header-solid th .header-text {
  opacity: 0.5;
}

.btn-loadmore {
  margin: 0 auto;
  cursor: pointer;
  width: 200px;
  display: block;
  color: #265f14;
  font-weight: 600;
  text-shadow: 1px 1px rgba(255, 255, 255, 1);
  font-size: 1.1em;
  height: 100%;
}

tfoot tr:hover {
  background: rgb(255, 255, 255);
}

tfoot tr td {
  text-align: center;
}

.load-more-container {
  padding-top: 0px;
  line-height: 47px;
}

.select-chapters-text {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.bottom {
  bottom: 25px;
}

.document-preview strong {
  text-transform: uppercase;
}

.search-disclaimer {
  font-style: italic;
  font-size: 1.2em;
  padding-left: 2em;
  padding-right: 2em;
}

th:nth-child(5) {
  width: 60%;
}

th:nth-child(6) {
  width: 90px;
}

.copy-icon {
  font-size: medium;
}
</style>
