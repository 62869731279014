import {
  getAllIMsRequest,
  getIMRequest,
  updateIMRequest,
  deleteIMRequest,
  createIMRequest,
  uploadLogoRequest,
} from '@/services/ims.service';
import generic from '../utils/order';

// initial state
const state = () => ({
  ...generic.state,
  data: [],
  current: {},
  totalItems: 0,
});

// getters
const getters = {
  totalItems: (state) => {
    if (!state.current) return 0;
    if (!state.current.documentManagers) return 0;
    return state.current.documentManagers.length;
  },
};

// actions
const actions = {
  ...generic.actions,
  getAllIMs({ commit }, { cb = null }) {
    getAllIMsRequest((ims) => {
      const data = [...ims];
      data.sortBy('name');
      commit('setIMs', data);
      if (cb) cb();
    });
  },
  getSingleIM({ commit }, { id, cb = null, ecb = null }) {
    getIMRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleIM', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateSingleIM({ commit }, { data, cb = null, ecb = null }) {
    updateIMRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleIM', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  createSingleIM({ commit }, { data, cb = null, ecb = null }) {
    createIMRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleIM', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  deleteSingleIM({ commit }, { id, cb = null, ecb = null }) {
    deleteIMRequest(
      id,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) commit('setSingleIM', res);
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  uploadLogo({ dispatch }, { data, cb = null, ecb = null }) {
    uploadLogoRequest(
      data,
      (res) => {
        if (cb) cb(res);
        const hasError = Object.prototype.hasOwnProperty.call(res, 'error');
        if (!hasError) dispatch('getSingleIM', { id: data.id });
      },
      (err) => {
        if (ecb) ecb(err);
      }
    );
  },
  updateName({ commit }, name) {
    commit('updateName', name);
  },
  updateCode({ commit }, name) {
    commit('updateCode', name);
  },
  updateCountry({ commit }, name) {
    commit('updateCountry', name);
  },
  clearCurrent({ commit }) {
    commit('clearCurrent');
  },
};

// mutations
const mutations = {
  ...generic.mutations,
  setIMs(state, data) {
    state.data = data;
    state.totalItems = data.length;
  },
  setSingleIM(state, data) {
    state.current = data;
  },
  updateName(state, name) {
    state.current.name = name;
  },
  updateCode(state, name) {
    state.current.localizedName = name;
  },
  updateCountry(state, name) {
    state.current.country = name;
  },
  clearCurrent(state) {
    state.current = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
